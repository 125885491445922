<template>
  <div
    v-if="show"
    ref="edit-menu-modal"
    class="modal modal__right"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    id="addModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <!--CONFIRM DIALOG-->
    <div
      class="modal modal__confirm show"
      id="confirmModal"
      tabindex=""
      role="dialog"
      aria-labelledby="modalLabel"
      v-bind:style="{ display: confirm ? 'block' : 'none' }"
      aria-hidden="true"
    >
      <div class="w-100 h-100 d-flex">
        <div class="modal__dialog modal__dialog_alert" role="document">
          <div class="modal__header mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="modal__header__title text--capital">
                confirmation
              </span>
              <a
                class="modal__close modal__close--white d-flex align-items-center justify-content-between"
                @click="confirmSave()"
              >
                <span class="text--capital text--regular"> close </span>
                <ion-icon name="close-outline"></ion-icon>
              </a>
            </div>
            <span class="modal__header__subtitle text--capital">
              For Indicators
            </span>
          </div>
          <div class="modal__content">
            <div class="modal__body">
              <h5 class="modal__head__title text--capital mb-3">
                Are you sure you want to proceed?
              </h5>
              <p class="form__label"></p>
              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="confirmSave()"
                >
                  cancel
                </button>
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="save()"
                >
                  {{ process ? "Saving..." : "Save" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--CREATE MENU MODAL-->
    <div class="modal__dialog" role="document">
      <div class="modal__content">
        <div class="modal__head">
          <div class="modal__head__icon">
            <a class="modal__close modal__close--black" @click="$emit('close')">
              <ion-icon name="arrow-back-outline"></ion-icon>
            </a>
          </div>
          <h5 class="modal__head__title text--capital">Configure Indicators</h5>
        </div>
        <div class="modal__body">
          <form>
            <div class="row">
              <div class="col-12">
                <div class="form__item">
                  <label class="form__label text--black text--capital mb-3"
                    >Indicator</label
                  >
                  <select class="multisselect" multiple v-if="hasUpdated">
                    <template v-for="item in allIndicators">
                      <option
                        v-if="checkSel(item) == true"
                        selected="selected"
                        :value="`${item.indicatorKey}`"
                        :key="item.indicatorKey"
                      >
                        {{ item.description }}
                      </option>
                      <option
                        v-else
                        :value="`${item.indicatorKey}`"
                        :key="item.indicatorKey"
                      >
                        {{ item.description }}
                      </option>
                    </template>
                  </select>
                </div>
              </div>
            </div>

            <div class="form__item mt-4">
              <div class="form__action">
                <button
                  id="save-changes"
                  type="button"
                  class="button form__button form__button--lg"
                  @click="confirmSave()"
                >
                  {{ process ? "Saving..." : "save changes" }}
                </button>
                <a
                  role="button"
                  class="modal__close form__action__text form__action__text--danger text--capital ml6"
                  @click="$emit('close')"
                >
                  cancel
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import ApiService from "@/core/services/api.service";
import { GET_MENU } from "@/core/services/store/types";
export default {
  name: "EditIndicator",
  components: {},
  props: {
    show: Boolean,
    close: Function,
    dashboardStats: Object,
    allIndicators: Array,
  },
  data() {
    return {
      userIndicators: [],
      indicators: [],
      menuParent: {},
      noEdit: true,
      hasUpdated: false,
      confirm: false,
      process: false,
    };
  },
  methods: {
    getAllIndicators() {
      this.$store.dispatch(GET_MENU);
    },
    resetWindow: function () {
      // Object.assign(this.$data, initialState());
    },
    checkSel(e) {
      let val = `${e.description}`.replace(/ /g, "");
      if (this.userIndicators.includes(val)) {
        return true;
      } else {
        return false;
      }
    },
    transformIndicator(obj) {
      let rel = [];
      for (let x in obj) {
        rel.push(x);
      }
      this.hasUpdated = true;
      return rel;
    },
    confirmSave() {
      if (!this.confirm) {
        this.confirm = true;
      } else {
        this.confirm = false;
      }
    },
    noEditSuccess() {
      this.confirm = false;
      this.process = false;
      this.$emit("alertSuccess", "Success");
      this.$emit("close");
      this.$emit("reload");
    },
    save: function () {
      this.process = true;

      if (this.noEdit) {
        this.noEditSuccess();
        return;
      }
      let copyOfPayload = Object.assign(this.$data);
      this.userIndicators.map((e) => {
        copyOfPayload.indicators.push(e);
      });
      ApiService.put(`dashboardIndicators/Edituserindicator`, copyOfPayload)
        .then((res) => res.data)
        .then((res) => {
          this.confirm = false;
          this.process = false;
          if (res.status) {
            this.$emit("alertSuccess", res.message);
            // this.resetWindow();
            this.$emit("close");
            this.$emit("reload");
          } else {
            this.$emit("alertError", res.message);
          }
        })
        .catch((e) => {
          this.confirm = false;
          try {
            this.process = false;
            this.$emit("alertError", e.response.data.message);
          } catch (e) {
            this.$emit(
              "alertError",
              "An unexpected Error occurred: Kindly confirm you are connected to the internet"
            );
          }
        });
    },
  },
  watch: {
    dashboardStats(nextProps) {
      const ob = { ...this.$data };
      var self = this;
      Object.keys(nextProps).map(function (key) {
        if (ob.hasOwnProperty(key) && nextProps.hasOwnProperty(key)) {
          self[key] = self.transformIndicator(nextProps[key]);
          // self.hasUpdated = true
        }
      });
    },
  },
  computed: {
    loading() {
      return this.$store.state.loan.loading_all;
    },
    saving() {
      return this.$store.state.loan.saving;
    },
  },
  mounted() {
    this.getAllIndicators();
    const self = this;
    this.userIndicators = this.transformIndicator(
      this.dashboardStats.userIndicators
    );
    // this.userIndicators =
    setTimeout(function () {
      window.$(".multisselect").selectize({
        plugins: ["remove_button", "drag_drop"],
        onChange: function (value) {
          //check later to see
          //if user tried changing this select
          //if yes, I am save, cos the right value(key,desription)
          //would be updated
          //else just send user success
          //since they didnt change anything and i dont have key from user indicators
          self.noEdit = false;
          if (value == null) {
            self.userIndicators = [];
            return;
          }
          self.userIndicators = value;
        },
      });
    }, 500);
  },
};
</script>
